.site-logo {
    width: 400px;
    max-width: calc(100% - 20px);
    
    @media (max-width: 400px) {
        max-width: calc(100% - 10px);
    }
}

.footnotes {
    border-top: 1px solid #dedede;
    padding-top:5px;
    font-size: 0.9em;
}

.video-container {
    overflow: hidden;
    position: relative;
    width:100%;
    margin: 20px 0;


   &::after {
        padding-top: 56.25%;
        display: block;
        content: '';
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

$blockquote-text-colour: #454545;
blockquote {
    color: $blockquote-text-colour;
    letter-spacing: 0;
    font-size: 1.2em;
    background-color: #f8f8f8;
    border-left-color: #989898;
    padding: 10px 10px 10px 15px; 

    .source {
        font-style: normal;
        font-size: 0.8em;
        display: block;
        color: scale-color($color: $blockquote-text-colour, $lightness: -40%);
        text-align: right;
    }
}

p + blockquote {
    margin-top: -5px;
}