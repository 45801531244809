.reflection {
    display: grid;
    grid-template-columns: minmax(200px, 30%) 1fr;

    @media (max-width:560px) {
      display:block;
    }
  
  .reflection-overview {
    font-style: italic;
    font-size: 1.2em;
    font-family: Georgia, Times, 'Times New Roman', serif;
    color:#444;
  }
  .reflection-title {
    margin-bottom: 0;
  }

  .post-header {
    grid-column: 1/3;
    grid-row: 1;
  }
  .post-content {
    grid-column: 2;
    grid-row: 2;
  }
  .reflection-listing {
    grid-column: 1;
    grid-row: 2;
    padding-right:10px;
    
    @media (min-width:960px) {
      margin-left:-100px;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    li {
      margin:0;
      
      a {
        padding:3px;
        display: inline-block;
        width:100%;

        &:hover {
          background: #efefef;
        }
      }
    }
    .current {
      background: #cfcdcd;
    }
  }
}